<template>
    <div class="loginWrapper">
        <router-view />
    </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.loginWrapper {
	width: 100vw;
	height: 100vh;
	background-image: url('../assets/login_bg.jpg');
	background-size: cover;
}
</style>