
<template>
      <!-- <v-container class="mt-3 mx-5">
        <h3>Hi</h3>
        <v-text-field
              label="Outlined"
              outlined
              dense
            ></v-text-field>         
      </v-container> -->
      <h3>Hi, this is about</h3>
</template>

<script setup>

</script>

<style lang="scss" scoped>


.v-text-field--outlined fieldset {
    color: red !important;
}
</style>
