import { defineStore } from "pinia";
import fetchClient from "@/utils/fetchClient";

export const useDishTypeStore =  defineStore('dishTypeStore', {
    state:() => ({
        dishTypes: [],
    }),
    getters:{
        getDishTypes: (state) => {
            return state.dishTypes;
        }
    },
    actions: {
        async fetchDishTypes () {
            // fetch dishtypes from contentful, CDA
            try {
                const response = await fetchClient.get('/api/dishtypes');
                if(response.data) {
                    this.$state.dishTypes = response.data;
                } else {
                    alert('No dishtypes currently');
                }
            } catch (error) {
                console.error('Error fetching dish types:', error);
            }
        },
        async createDishType(newDishType) {
            try {
                const response = await fetchClient.post('/api/dishtypes/create', {dishTypeData:newDishType});
                if(response.data) {
                    console.log(`The created dishtype name is`, response.data);
                    await this.fetchDishTypes();
                }
            } catch (error) {
                console.error('Error creating dish type:', error);
            }
        },
        async editDishType(id, newDishtypeData) {
            // edit dishType name or alias
            // console.log(id);
            console.log(`dishtypeData is`, newDishtypeData);
            await fetchClient.put(`/api/dishtypes/update/${id}`, {
                dishTypeData: newDishtypeData
            });
            await this.fetchDishTypes();         
        },
        async deleteDishType(id) {
            // think about using the id being generated by contentful 
            // console.log(id);
            await fetchClient.delete(`/api/dishtypes/delete/${id}`);
            await this.fetchDishTypes();         
        },
    
    }
})